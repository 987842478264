import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import ls from 'local-storage';
import Cookie from 'js-cookie'; // eslint-disable-line no-unused-vars

import './App.css';
import AppRoutes from './AppRoutes';
import axios from 'axios';
import { apiConfig } from './apiConfig';
import UserContext from './context/UserContext';
import SiteContext from './context/SiteContext';
import Header from './components/Header';
import Footer from './components/Footer';
import LoggedInLayout from './components/LoggedInLayout';

function App() {
  let navigate = useNavigate();
  let token = null;

  const [user, setUser] = useState(null);
  const [siteContext, setSiteContext] = useState(null);

  // Get the token from local storage or cookie
  token = ls.get('token');
  if (!token) {
    token = Cookie.get('token');
    if (token) ls.set('token', token);
  }

  // List public routes that don't require authentication
  const publicRoutes = [
    '/signin',
    '/register',
    '/register/',
    '/register/coach',
    '/register/coach/',
    '/forgot-password',
    '/forgot-password/',
    '/resetpassword',
  ];

  // assume all pages are restricted to logged-in users unless listed here
  const isPublicRoute = (path) => {
    return publicRoutes.includes(path);
  };

  // is this the route for unrestricted access to a report?
  const isDirectReportRoute = (path) => {
    // regex checks that the path is /r/ followed by at least one more character
    return path.search(/\/r\/.{1,}/) >= 0;
  };

  useEffect(() => {
    onRouteChanged();
  }, [location.pathname, user]); // eslint-disable-line react-hooks/exhaustive-deps

  const onRouteChanged = () => {
    if (
      isPublicRoute(location.pathname) ||
      isDirectReportRoute(location.pathname)
    )
      return;

    // If no token is stored then we redirect to /signin
    if (!token) navigate('/signin');

    // if there is a token but no user context yet, return - that will happen in fetchData()
    if (!user) return;

    // if user has not paid, we redirect to the portal
    if (user.type !== 'COACH' && user.has_paid === false) {
      navigate('/portal');
    }
  };

  useEffect(() => {
    // do nothing if user is logged in - the cookie is irrelevant
    if (token) return;

    // get the coach referral code cookie
    let coachReferralCode = Cookie.get('r');
    if (coachReferralCode) {
      setSiteContext((siteContext) => {
        return {
          ...siteContext,
          coachReferralCode: coachReferralCode,
        };
      });
    }
  }, [token]);

  useEffect(() => {
    if (!token) return;

    // get user record via token exchange, set as the user context
    axios
      .post(
        `${apiConfig.baseUrl}/token/exchange/`,
        { token: token },
        { auth: apiConfig.auth }
      )
      .then((res) => {
        setUser(res.data);
      })
      .catch(() => {
        ls.clear();
        Cookie.remove('token', {
          domain: '.leftbrainperformance.com',
        });
        if (!isPublicRoute(location.pathname)) navigate('/signin');
      });
  }, [token, navigate]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <UserContext.Provider value={{ user, setUser }}>
      <SiteContext.Provider value={{ siteContext, setSiteContext }}>
        {isPublicRoute(location.pathname) ? (
          <>
            <Header />
            <div className="App">
              <AppRoutes />
            </div>
            <Footer />
          </>
        ) : (
          <LoggedInLayout
            restricted={isDirectReportRoute(location.pathname)}
            isCoach={user && user.type === 'COACH'}
          >
            <div className="App">
              <AppRoutes />
            </div>
          </LoggedInLayout>
        )}
      </SiteContext.Provider>
    </UserContext.Provider>
  );
}

App.propTypes = {
  location: PropTypes.object,
};

export default App;
