import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import ReportPercentileBar from './ReportPercentileBar';
import { ProcessNodeDefinitions, Parser } from 'html-to-react';

const htmlToReactParser = new Parser();
const processNodeDefinitions = new ProcessNodeDefinitions();

const HtmlConverter = ({ html }) => {
  const [convertedHtml, setConvertedHtml] = useState(null);

  // stub function that always returns true
  const isValidNode = function () {
    return true;
  };

  useEffect(() => {
    // processing instructions; order matters, the first match is used.
    const processingInstructions = [
      {
        /* ProgressBar */
        shouldProcessNode: function (node) {
          return node.attribs && node.attribs['class'] === 'progress';
        },
        processNode: function (node) {
          // get data from placeholder div, to be used in ReportPercentileBar component
          let percentile = node.attribs['percentile'];
          let percentileDescription = node.attribs['percentile_description'];
          let percentileInterpretation =
            node.attribs['percentile_interpretation'];
          let colorNumber = node.attribs['color'];
          return (
            <ReportPercentileBar
              percentileValue={percentile}
              displayText={
                percentileDescription + ' - ' + percentileInterpretation
              }
              colorNumber={colorNumber}
            />
          );
        },
      },
      {
        /* everything else catch-all */
        shouldProcessNode: function () {
          return true;
        },
        processNode: processNodeDefinitions.processDefaultNode,
      },
    ];

    let output = htmlToReactParser.parseWithInstructions(
      html,
      isValidNode,
      processingInstructions
    );

    setConvertedHtml(output);
  }, [html]);

  return <>{convertedHtml}</>;
};

HtmlConverter.propTypes = {
  html: PropTypes.string,
};

export default HtmlConverter;
