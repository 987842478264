/* SET ENVIRONMENT VARIABLE DEFAULTS IE: LOCAL DEVELOPMENT */

let env_appURL = 'http://localhost:3000';
let env_apiURL = 'http://127.0.0.1:8000';
let env_publicURL = 'http://leftbrainperformance.com';
let env_apiUsername = 'admin@admin.com';
let env_apiPassword = 'admin';
let env_peloton_CAD_payment_url =
  'https://testleftbrain.peloton-technologies.com/4fb9f518-a480-4cc6-94ac-cf3f4f32df6a';

// WE WILL NEED TO CHANGE THIS TO USD URL WHEN PELOTON HAS IT
let env_peloton_USD_payment_url =
  'https://testleftbrain.peloton-technologies.com/4fb9f518-a480-4cc6-94ac-cf3f4f32df6a';

let env_ipToken = 'bbdc20ffc386c9';

/* SET STAGING AND PRODUCTION OVERRIDES */

// Get environment from our environment variable REACT_APP_ENV set in the `npm build-xxx` command.
// If it is not set, use development environment variables, including for basic `npm build` and `npm run test` commands.
let ENV = process.env.REACT_APP_ENV ?? 'development';

if (ENV === 'staging') {
  env_apiURL = 'https://lbp-api-staging.nn.r.appspot.com';
  env_apiUsername = 'admin@lbp.com';
  env_apiPassword = ';B;uPQb"2:gKY#Gn';
  env_appURL = 'https://lbp-website-staging.web.app';
}

if (ENV === 'production') {
  env_apiURL = 'https://lbp-api-prod.nn.r.appspot.com';
  env_apiUsername = 'admin@lbp.com';
  env_apiPassword = '+[{M698~x7#Sft:-';
  env_peloton_CAD_payment_url =
    'https://leftbrain.peloton-technologies.com/4fb9f518-a480-4cc6-94ac-cf3f4f32df6a';
  env_peloton_USD_payment_url =
    'https://leftbrain.peloton-technologies.com/2878346b-0979-453e-addd-16f68eddcb5d';
  env_appURL = 'https://app.leftbrainperformance.com';
}

export const apiURL = env_apiURL;
export const apiUsername = env_apiUsername;
export const apiPassword = env_apiPassword;
export const pelotonPaymentCADUrl = env_peloton_CAD_payment_url;
export const pelotonPaymentUSDUrl = env_peloton_USD_payment_url;
export const ipToken = env_ipToken;
export const appURL = env_appURL;
export const publicURL = env_publicURL;
