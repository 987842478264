import React, { Component, Suspense, lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import Spinner from '../assets/Spinner';
import PortalPage from './pages/PortalPage';
import ProfilePage from './pages/ProfilePage';
import ResultPage from './pages/ResultPage';
import ResultPageRestricted from './pages/ResultPageRestricted';

/* LBP */
const AthleteRegistrationPage = lazy(() =>
  import('./pages/AthleteRegistrationPage')
);

const AssessmentPage = lazy(() => import('./pages/AssessmentPage'));
const LoginPage = lazy(() => import('./pages/LoginPage'));
const LogoutPage = lazy(() => import('./pages/LogoutPage'));
const FakeAfterLoginPage = lazy(() => import('./pages/FakeAfterLoginPage'));
const ForgotPasswordPage = lazy(() => import('./pages/ForgotPasswordPage'));
const ResetPasswordPage = lazy(() => import('./pages/ResetPasswordPage'));
const CoachRegistrationPage = lazy(() =>
  import('./pages/CoachRegistrationPage')
);

class AppRoutes extends Component {
  render() {
    return (
      <Suspense fallback={<Spinner />}>
        <Routes>
          {/* LBP Routes */}
          <Route path="/portal" element={<PortalPage />} />
          <Route path="/afterlogin" element={<FakeAfterLoginPage />} />
          <Route path="/signin" element={<LoginPage />} />
          <Route path="/logout" element={<LogoutPage />} />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          <Route path="/resetpassword" element={<ResetPasswordPage />} />
          <Route path="/assessment" element={<AssessmentPage />} />
          <Route
            path="/results/:athleteId/:resultId"
            element={<ResultPage />}
          />
          <Route path="/r/:directLinkId" element={<ResultPageRestricted />} />
          <Route path="/results" element={<ResultPage />} />
          <Route path="/register/coach" element={<CoachRegistrationPage />} />
          <Route path="/register" element={<AthleteRegistrationPage />} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/" element={<LoginPage />} />
          {/* root url has to go last */}
        </Routes>
      </Suspense>
    );
  }
}

export default AppRoutes;
