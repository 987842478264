import React, { useState } from 'react';
import { PropTypes } from 'prop-types';

const ReportPercentileBar = ({ percentileValue, displayText, colorNumber }) => {
  const [style, setStyle] = useState({});

  setTimeout(() => {
    const newStyle = {
      opacity: 1,
      width: `${percentileValue}%`,
    };

    setStyle(newStyle);
  }, 200);

  return (
    <div className="progress" data-testid="reportpercentilebar">
      <div className={`progress-done style-${colorNumber}`} style={style}>
        {displayText}
      </div>
    </div>
  );
};

ReportPercentileBar.propTypes = {
  percentileValue: PropTypes.string,
  displayText: PropTypes.string,
  colorNumber: PropTypes.string,
};

export default ReportPercentileBar;
