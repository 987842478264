/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import _ from 'lodash';
import {
  Accordion,
  AccordionContext,
  Button,
  Nav,
  useAccordionButton,
} from 'react-bootstrap';
import UserContext from '../context/UserContext';
import CoachPortalSidebarContext from '../context/CoachPortalSidebarContext';
import * as FaIcons from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPeople, faCircleSmall } from '@fortawesome/pro-solid-svg-icons';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { apiConfig } from '../apiConfig';
import { publicURL } from '../environment';

const SidebarCoach = () => {
  const location = useLocation();

  const { user } = useContext(UserContext);
  const { setSidebarState } = useContext(CoachPortalSidebarContext);
  const [menu, setMenu] = useState(null);

  const isPathActive = (path) => {
    return location.pathname.startsWith(path);
  };

  const showSportListing = () => {
    return isPathActive('/portal');
  };

  useEffect(() => {
    // only get the sports list if we are displaying the sport listing
    if (!showSportListing()) return;

    if (!user) return;

    // find the coach's school id
    let coach_school = null;
    if (
      user.schools_shared_to !== undefined &&
      user.schools_shared_to.length > 0
    ) {
      coach_school = user.schools_shared_to[0].id;
    }
    if (coach_school === null) return;

    axios
      .get(`${apiConfig.baseUrl}/schools/${coach_school}/sports/`, {
        auth: apiConfig.auth,
      })
      .then((res) => {
        let sports = res.data;
        let sportsList = [];

        // if coach has sports assigned, limit to those sports
        if (user.sports.length > 0) {
          sports = sports.filter((elem) => {
            return (
              user.sports.filter((elem2) => elem2.id === elem.id).length > 0
            );
          });
        }

        // build sports list by sport, gender, and position
        _.forEach(sports, (sport) => {
          // is this a men's/women's/mixed sport?
          let sportName = sport.name;
          let sportGender = null;
          if (sportName.search(/women's/gi) === 0) {
            sportName = sport.name.replace("Women's ", '');
            sportGender = 'Women';
          } else if (sportName.search(/men's/gi) === 0) {
            sportName = sport.name.replace("Men's ", '');
            sportGender = 'Men';
          } else if (sportName.search(/mixed/gi) === 0) {
            sportName = sport.name.replace('Mixed ', '');
            sportGender = 'Mixed';
          }
          let sportPositions = sport.positions.map((elem) => elem.name);

          // find this sport (without gender prefix) in the list, or create it
          /* ie: Men's Hockey will add "Hockey", so when we get to Women's
             Hockey, we don't need to add "Hockey", just add the new gender to
             it. */
          let foundElem = sportsList.find(
            (element) => element.sportName === sportName
          );
          if (foundElem === undefined) {
            foundElem = {
              sportName: sportName,
              genders: [],
              positions: sportPositions,
            };
            sportsList.push(foundElem);
          }
          if (sportGender) foundElem.genders.push(sportGender);
        });

        // sort sports alphabetically (case-insensitive to handle eSports)
        sportsList.sort((a, b) =>
          a.sportName.toLowerCase() > b.sportName.toLowerCase()
            ? 1
            : b.sportName.toLowerCase() > a.sportName.toLowerCase()
            ? -1
            : 0
        );
        setMenu(sportsList);
      });
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  const sidebarClick = (sport, gender = null, position = null) => {
    setSidebarState({ sport: sport, gender: gender, position: position });
  };

  function ContextAwareToggle({
    children,
    eventKey,
    callback,
    className,
    onClick,
  }) {
    const currentEventKey = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(
      eventKey,
      () => callback && callback(eventKey)
    );

    const isCurrentEventKey = currentEventKey === eventKey;

    return (
      <Button
        variant="link"
        className={className}
        onClick={(e) => {
          decoratedOnClick(e);
          if (onClick) onClick(e); // Call onClick prop if provided
        }}
      >
        {isCurrentEventKey || eventKey === 'all sports' ? (
          <FaIcons.FaRegMinusSquare />
        ) : (
          <FaIcons.FaRegPlusSquare />
        )}
        {children}
      </Button>
    );
  }

  return (
    <div
      data-testid="sidebarcoach-container"
      className="sidebarcoach-container"
    >
      {user.completed_initial_assessment ? (
        <Nav.Item>
          <Nav.Link href="/results">
            <FaIcons.FaFileAlt />
            <span>My Report</span>
          </Nav.Link>
        </Nav.Item>
      ) : (
        <div style={{ textAlign: 'center' }}>
          <Button style={{ width: '90%' }} href={'/assessment'}>
            Take Assessment
          </Button>
        </div>
      )}
      <Nav.Item data-testid="sidebarcoach-profilelink">
        <Nav.Link href="/profile">
          <FaIcons.FaLink />
          <span>Find My Referral Link</span>
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link
          href="/portal"
          className={isPathActive('/portal') ? 'active' : null}
        >
          <FontAwesomeIcon icon={faPeople} />
          <span>Athlete Listing</span>
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link href={`${publicURL}/contact/`} target="_blank">
          <FaIcons.FaMailBulk />
          <span>Contact Us</span>
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link href={`${publicURL}/help/`} target="_blank">
          <FaIcons.FaQuestion />
          <span>Help &amp; FAQ</span>
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link href={`${publicURL}/about/`} target="_blank">
          <FaIcons.FaBrain />
          <span>About Us</span>
        </Nav.Link>
      </Nav.Item>

      {menu && showSportListing() && (
        <Accordion
          className="coach-portal-sidebar"
          data-testid="sidebarcoach-sportlisting"
        >
          <ContextAwareToggle
            eventKey={'all sports'}
            key={'accordiontoggle_allsports'}
            className="sidebar-link allsports"
            onClick={() => sidebarClick('')}
          >
            All Sports
          </ContextAwareToggle>
          {menu.map((sport) => (
            <div key={'div_' + sport.sportName}>
              <ContextAwareToggle
                as={Button}
                variant="link"
                eventKey={sport.sportName}
                key={'accordiontoggle_' + sport.sportName}
                className="sidebar-link"
                onClick={() => sidebarClick(sport.sportName)}
              >
                {sport.sportName}
              </ContextAwareToggle>
              <Accordion.Collapse
                eventKey={sport.sportName}
                key={'accordioncollapse_' + sport.sportName}
              >
                {sport.genders.length > 0 && sport.positions.length > 0 ? (
                  <>
                    <Accordion key={'accordion_' + sport.sportName}>
                      {sport.genders.map((gender) => (
                        <div key={'div2' + sport.sportName + gender}>
                          <ContextAwareToggle
                            as={Button}
                            variant="link"
                            eventKey={sport.sportName + gender}
                            key={'accordiontoggle2_' + sport.sportName + gender}
                            className="sidebar-link"
                            onClick={() =>
                              sidebarClick(sport.sportName, gender)
                            }
                          >
                            {gender}
                          </ContextAwareToggle>
                          <Accordion.Collapse
                            eventKey={sport.sportName + gender}
                            key={
                              'accordioncollapse2_' + sport.sportName + gender
                            }
                          >
                            <>
                              {sport.positions.length > 0 ? (
                                <>
                                  {sport.positions.map((position) => (
                                    <Button
                                      variant="link"
                                      key={
                                        'button1_' +
                                        sport.sportName +
                                        gender +
                                        position
                                      }
                                      className="sidebar-link"
                                      onClick={() =>
                                        sidebarClick(
                                          sport.sportName,
                                          gender,
                                          position
                                        )
                                      }
                                    >
                                      <FontAwesomeIcon icon={faCircleSmall} />
                                      {position}
                                    </Button>
                                  ))}
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          </Accordion.Collapse>
                        </div>
                      ))}
                    </Accordion>
                  </>
                ) : sport.genders.length > 0 ? (
                  <>
                    {sport.genders.map((gender) => (
                      <Button
                        variant="link"
                        key={'button2_' + sport.sportName + gender}
                        className="sidebar-link"
                        onClick={() =>
                          sidebarClick(sport.sportName, gender, null)
                        }
                      >
                        <FontAwesomeIcon icon={faCircleSmall} />
                        {gender}
                      </Button>
                    ))}
                  </>
                ) : sport.positions.length > 0 ? (
                  <>
                    {sport.positions.map((position) => (
                      <Button
                        variant="link"
                        key={'button3_' + sport.sportName + position}
                        className="sidebar-link"
                        onClick={() =>
                          sidebarClick(sport.sportName, null, position)
                        }
                      >
                        <FontAwesomeIcon icon={faCircleSmall} />
                        {position}
                      </Button>
                    ))}
                  </>
                ) : (
                  <></>
                )}
              </Accordion.Collapse>
            </div>
          ))}
        </Accordion>
      )}
    </div>
  );
};

SidebarCoach.propTypes = {
  sportName: PropTypes.string,
};
export default SidebarCoach;
