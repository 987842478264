import React, { useContext } from 'react';
import UserContext from '../context/UserContext';
import { Navbar } from 'react-bootstrap';
import SidebarCoach from './SidebarCoach';

function Sidebar() {
  const { user } = useContext(UserContext);

  return (
    user &&
    user.type === 'COACH' && (
      <Navbar
        variant="light"
        className="col-md-3 col-xl-2 d-none d-md-block sidebar"
      >
        <div className="sidebar-inside">
          <SidebarCoach />
        </div>
      </Navbar>
    )
  );
}

export default Sidebar;
