import React from 'react';

export default function Footer() {
  return (
    <footer>
      <div id="footer_grid" className="container grid_1_1">
        <div>
          <div className="spacer" style={{ marginBottom: 10 }}></div>
          <div id="media_icons">
            <a
              href="https://www.facebook.com/leftBrainPerformance/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt="Facebook"
                title="Facebook"
                src="https://leftbrainperformance.com/img/template/fb.png"
              />
            </a>
            <a
              href="https://twitter.com/leftBrainPerf/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt="Twitter"
                title="Twitter"
                src="https://leftbrainperformance.com/img/template/twitter.png"
              />
            </a>
            <a
              href="https://www.instagram.com/leftBrainPerformance/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt="Instagram"
                title="Instagram"
                src="https://leftbrainperformance.com/img/template/insta.png"
              />
            </a>
            <a
              href="https://www.tiktok.com/@leftBrainPerformance/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt="TikTok"
                title="TikTok"
                src="https://leftbrainperformance.com/img/template/tiktok.png"
              />
            </a>
            <a
              href="https://www.linkedin.com/company/leftBrainPerformance/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt="LinkedIn"
                title="LinkedIn"
                src="https://leftbrainperformance.com/img/template/linkedin.png"
              />
            </a>
          </div>
          <div className="spacer"></div>
          ©2020-2021{' '}
          <a href="https://leftbrainperformance.com">leftBrain Performance</a>
          <br />
          Canada, All Rights Reserved
        </div>
        <div>
          <a href="https://leftbrainperformance.com">
            <img
              src="https://leftbrainperformance.com/img/template/logo_white.svg"
              alt="leftBrain Performance"
              title="leftBrain Performance"
              id="footer_logo"
            />
          </a>
          <br />
          <a href="/signin">Sign In</a>
          <br />
          <a href="https://leftbrainperformance.com/about/">About Us</a>
          <br />
          <a href="https://leftbrainperformance.com/help/">Help &amp; FAQ</a>
          <br />
          <a href="https://leftbrainperformance.com/terms/">
            Terms &amp; Conditions
          </a>
          <br />
          <a href="https://leftbrainperformance.com/privacy/">Privacy Policy</a>
          <br />
          <a href="https://leftbrainperformance.com/contact/">Contact Us</a>
        </div>
      </div>
    </footer>
  );
}
