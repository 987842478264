import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { apiConfig } from '../apiConfig';
import HtmlConverter from '../components/HtmlConverter';
import Alert from 'react-bootstrap/Alert';
import { Col, Row } from 'react-bootstrap';
import ResultPageLoading from '../components/ResultPageLoading';

const ResultPageRestricted = () => {
  const { directLinkId } = useParams();
  const [resultHtml, setResultHtml] = useState(null);
  const [reportUser, setReportUser] = useState(null);
  const [resultData, setResultData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // get limited user record and results
    axios
      .get(`${apiConfig.baseUrl}/results/limited/${directLinkId}/`, {
        auth: apiConfig.auth,
      })
      .then((res) => {
        setLoading(false);
        setReportUser(res.data['user']);
        setResultData(res.data);
        setResultHtml(res.data['coach_report']);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [directLinkId]);

  return (
    <div data-testid="results-container" className="page-results">
      {loading ? (
        <ResultPageLoading />
      ) : (
        <>
          <>
            {reportUser && (
              <>
                <Alert
                  variant="info"
                  className="coach-view-athlete-info"
                  data-testid="coach-report-athlete-info"
                >
                  <div className="coach-report-athlete-info-title">
                    Athlete Info
                  </div>
                  <hr />
                  <Row>
                    <div className="col-lg-6">
                      <Row className="mb-2 mb-sm-0">
                        <Col
                          md={4}
                          lg={5}
                          className="coach_report-athlete-info-header"
                        >
                          First Name
                        </Col>
                        <Col md={8} lg={7}>
                          {reportUser.first_name}
                        </Col>
                      </Row>
                      <Row className="mb-2 mb-sm-0">
                        <Col
                          md={4}
                          lg={5}
                          className="coach_report-athlete-info-header"
                        >
                          Last Name
                        </Col>
                        <Col md={8} lg={7}>
                          {reportUser.last_name ?? reportUser.last_initial}
                        </Col>
                      </Row>
                      <Row className="mb-2 mb-sm-0 mb-md-4">
                        <Col
                          md={4}
                          lg={5}
                          className="coach_report-athlete-info-header"
                        >
                          Email
                        </Col>
                        <Col md={8} lg={7}>
                          <div>
                            <a href="/signin">Sign in to view</a>
                          </div>
                        </Col>
                      </Row>
                      {reportUser.highschool_team && (
                        <Row className="mb-2 mb-sm-0">
                          <Col
                            md={4}
                            lg={5}
                            className="coach_report-athlete-info-header"
                          >
                            Highschool Team
                          </Col>
                          <Col md={8} lg={7}>
                            {reportUser.highschool_team}
                          </Col>
                        </Row>
                      )}
                      {reportUser.aau_team && (
                        <Row className="mb-2 mb-sm-0">
                          <Col
                            md={4}
                            lg={5}
                            className="coach_report-athlete-info-header"
                          >
                            AAU Team
                          </Col>
                          <Col md={8} lg={7}>
                            {reportUser.aau_team}
                          </Col>
                        </Row>
                      )}
                      {reportUser.travel_team && (
                        <Row className="mb-2 mb-sm-0">
                          <Col
                            md={4}
                            lg={5}
                            className="coach_report-athlete-info-header"
                          >
                            Travel Team
                          </Col>
                          <Col md={8} lg={7}>
                            {reportUser.travel_team}
                          </Col>
                        </Row>
                      )}{' '}
                      <Row className="mb-2 mb-sm-0 mt-md-4">
                        <Col
                          md={4}
                          lg={5}
                          className="coach_report-athlete-info-header"
                        >
                          Sport
                        </Col>
                        <Col md={8} lg={7}>
                          {reportUser.sport_name}
                        </Col>
                      </Row>
                      <Row className="mb-2 mb-sm-0">
                        <Col
                          md={4}
                          lg={5}
                          className="coach_report-athlete-info-header"
                        >
                          Position
                        </Col>
                        <Col md={8} lg={7}>
                          {reportUser.position_name}
                        </Col>
                      </Row>
                    </div>

                    <div className="col-lg-6 mt-md-4 mt-lg-0">
                      <Row className="mb-2 mb-sm-0">
                        <Col
                          md={4}
                          lg={6}
                          className="coach_report-athlete-info-header"
                        >
                          Registered
                        </Col>
                        <Col md={8} lg={6}>
                          {new Date(
                            reportUser.date_joined
                          ).toLocaleDateString()}
                        </Col>
                      </Row>
                      <Row className="mb-2 mb-sm-0">
                        <Col
                          md={4}
                          lg={6}
                          className="coach_report-athlete-info-header"
                        >
                          Completed
                        </Col>
                        <Col md={8} lg={6}>
                          {resultData ? (
                            <>
                              {new Date(
                                resultData.created_datetime
                              ).toLocaleDateString()}
                            </>
                          ) : (
                            <span>n/a</span>
                          )}
                        </Col>
                      </Row>
                      <Row className="mb-2 mb-sm-0">
                        <Col
                          md={4}
                          lg={6}
                          className="coach_report-athlete-info-header"
                        >
                          Shared Before Completion?
                        </Col>
                        <Col md={8} lg={6}>
                          {resultData ? (
                            <>
                              {resultData.shared_before_completion
                                ? 'Yes'
                                : 'No'}
                            </>
                          ) : (
                            <span>n/a</span>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </Row>
                </Alert>
              </>
            )}
            {resultHtml ? (
              <HtmlConverter html={resultHtml} />
            ) : (
              <div>No results found.</div>
            )}
          </>
        </>
      )}
    </div>
  );
};

export default ResultPageRestricted;
