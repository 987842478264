import React from 'react';
import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import ls from 'local-storage';
import axios from 'axios';
import { PropTypes } from 'prop-types';
import { apiConfig } from '../apiConfig';
import Cookie from 'js-cookie';

const UnapprovedMessage = () => {
  const navigate = useNavigate();
  const token = ls.get('token');

  const logout = () => {
    axios
      .delete(apiConfig.baseUrl + `/token/delete/${token}/`, {
        auth: apiConfig.auth,
      })
      .finally(() => {
        // we use finally instead of then because we want to logout whether the axios call was successful or not
        ls.clear();
        Cookie.remove('token', {
          domain: '.leftbrainperformance.com',
        });
        navigate('/signin');
      });
  };

  return (
    <Modal
      show={true}
      backdrop="static"
      centered
      style={{ textAlign: 'center' }}
    >
      <Modal.Body>
        <p id="modalTitle">
          Your registration is pending approval. Please check back later.
        </p>
        <div id="modalActions">
          <a
            id="logout"
            href="#"
            onClick={() => {
              logout();
            }}
          >
            Logout
          </a>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <p>
          Have any questions? please email{' '}
          <a href="mailto:support@leftbrainperformance.com">
            support@leftbrainperformance.com
          </a>
        </p>
      </Modal.Footer>
    </Modal>
  );
};

UnapprovedMessage.propTypes = {
  user: PropTypes.object,
};

export default UnapprovedMessage;
