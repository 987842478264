import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-duotone-svg-icons';

const ResultPageLoading = () => {
  return (
    <div style={{ textAlign: 'center' }} className="mt-5">
      <FontAwesomeIcon
        style={{ fontSize: 100 }}
        className="fa-spin"
        icon={faSpinner}
      />
    </div>
  );
};

export default ResultPageLoading;
