import { ipToken } from '../environment';
import axios from 'axios';

export async function getIpData(ipAddress) {
  let ipDetails = { country: '' };
  await axios
    .get(`https://ipinfo.io/${ipAddress}?token=${ipToken}`)
    .then((res) => (ipDetails = res.data))
    .catch(() => {
      // do nothing
    });

  return ipDetails;
}
